<template>
  <div class="room-detail">
    <div class="status-tab">
      <div
        :class="status === item.value ? 'status-item active' : 'status-item'"
        v-for="item in statusOptionsV2"
        :key="item.value"
        @click="changeStatus(item)"
      >
        <span class="word">{{ $i18n.t(item.name) }}</span>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <template slot="pulling">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loosing">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loading">
        {{ $i18n.t("rd_loading") }}
      </template>
      <van-list v-model="loading" :finished="finished" @load="loadList">
        <template slot="finished">
          {{ $i18n.t("rd_no_more") }}
        </template>
        <template slot="loading">
          {{ $i18n.t("rd_loading") }}
        </template>
        <div class="room-goods-list">
          <div
            class="room-goods-item"
            v-for="(item1, index1) in newGoodList[status] || []"
            :key="index1"
            @click="toDetail(item1)"
          >
            <div class="pic-box">
              <img :src="item1.imageUrl" />
              <div v-if="status === 'PENDING' || (item1.status === 'PENDING' && !item1.isTasking)" class="tag show">
                <span>{{ $i18n.t("rd_display") }}</span>
              </div>
              <div v-if="status === 'TASKING' || item1.isTasking" class="tag work">
                <span>{{ $i18n.t("rd_in_task") }}</span>
              </div>
              <div v-if="status === 'FINISHED' || item1.status === 'FINISHED'" class="tag done">
                <span>{{ $i18n.t("rd_Completed") }}</span>
              </div>
            </div>
            <div class="room-goods-info">
              <div class="room-goods-name">{{ item1.title }}</div>
              <div class="room-data-line">
                <div class="data-item">
                  <img src="../assets/images/icon-eye.svg" alt="" />
                  <span class="num">{{ item1.clicks }}</span>
                </div>
                <div class="data-item">
                  <img src="../assets/images/icon-collet.svg" alt="" />
                  <span class="num">{{ item1.likes }}</span>
                </div>
                <div class="price">
                  <img src="../assets/images/icon-eth-s.svg" alt="" />
                  <span class="num">{{ item1.price }}</span>
                </div>
              </div>
            </div>
            <div class="sale-out" v-if="false">
              <img src="../assets/images/sale-out.png" />
              <div class="word">{{ $i18n.t("rd_sold_out") }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import dayjs from 'dayjs'
// import { utcDateSubtract } from '../utils/day'
export default {
  data() {
    return {
      roomId: "",
      goodList: [],
      newGoodList: {},
      status: "ALL",
      statusOptionsV2: [
        {
          value: "ALL",
          name: "rd_all",
        },
        {
          value: "PENDING",
          name: "rd_display",
        },
        {
          value: "TASKING",
          name: "rd_in_task",
        },
        {
          value: "FINISHED",
          name: "rd_Completed",
        },
      ],
      refreshing: false,
      loading: false,
      finished: false,
    };
  },
  computed: {
    roomStatus() {
      return this.$store.state.user.roomStatus
    },
  },
  created() {
    this.roomId = this.$route.query.id ? this.$route.query.id : "";
    if (this.roomStatus) {
      this.status = this.roomStatus
      this.$store.commit('modifyRoomStatus', '')
    }
  },
  methods: {
    handleTime(date) {
      if (typeof date !== 'string') return date
      return date.replace(/-|\./g,"/").replace(' UTC', '')
    },
    utcDateSubtract(date) {
      if (typeof date !== 'string') return date
      date = `${date.replace(/\./g,"-").replace(' ', 'T')}Z`
      // console.log(date);
      // return dayjs.utc(date) > dayjs.utc()
      return date
    },
    changeStatus(item) {
      console.log(item.value);
      this.status = item.value;
      this.loadList();
    },
    toDetail(item) {
      this.$store.commit('modifyRoomStatus', this.status)
      const query = {
        id: item.id,
        isTasking: item.isTasking,
        isEnded: item.status === 'FINISHED'
      }
      this.$router.push({path: "/goods-detail", query});
    },
    loadList() {
      let that = this;
      that.goodList = [];
      this.$http.getEthPrice().then((_res) => {
        if (_res.code === 0) {
          const price = _res.data.ethPrice.price;
          this.$http
            .getGoodsList(
              "?levelId=" + this.roomId + "&status=ALL"
              // (this.status === "TASKING" ? "ALL" : this.status)
            )
            .then((res) => {
              if (res.code === 0 && res.data.list) {
                that.goodList = res.data.list.map((v) => {
                  return {
                    ...v,
                    price: (v.price / price).toFixed(3),
                  };
                });
                // if (that.status === "TASKING") {
                //   that.goodList = that.goodList.filter(
                //     (v) => dayjs() < dayjs(this.handleTime(v.showAt))
                //   );
                // }
                that.goodList.forEach((v) => {
                  // 任务中 是showAt时间 到 endAt时间的前一个小时
                  // v.isTasking = dayjs() < dayjs(this.utcDateSubtract(v.showAt))
                  v.isTasking = +dayjs() < dayjs(this.utcDateSubtract(v.endAt)) && dayjs() > dayjs(this.utcDateSubtract(v.showAt))
                });

                const taskingList = that.goodList.filter(v => {
                  return +dayjs() < dayjs(this.utcDateSubtract(v.endAt)) && dayjs() > dayjs(this.utcDateSubtract(v.showAt))
                })

                const pendingList = that.goodList.filter(v => {
                  // return dayjs(this.utcDateSubtract(v.showAt)) < dayjs(this.utcDateSubtract(v.endAt)) && dayjs() > dayjs(this.utcDateSubtract(v.showAt)) && v.status === "PENDING" && dayjs(this.utcDateSubtract(v.endAt)) > dayjs()
                  return dayjs(this.utcDateSubtract(v.showAt)) < dayjs(this.utcDateSubtract(v.endAt)) && dayjs() < dayjs(this.utcDateSubtract(v.showAt)) && v.status === "PENDING" && dayjs(this.utcDateSubtract(v.endAt)) > dayjs()
                })

                const finishedList = that.goodList.filter(v => {
                  return v.status === "FINISHED"
                })

                this.newGoodList = {
                  ALL: that.goodList,
                  TASKING: taskingList,
                  PENDING: pendingList,
                  FINISHED: finishedList,
                }
                
              }
              that.finished = true;
              that.loading = false;
              this.refreshing = false;
            });
        }
      });
    },
    onLoad() {
      this.loadList();
    },
    onRefresh() {
      this.loadList();
    },
  },
};
</script>

<style lang="less">
.room-detail {
  padding-top: 56px;
  .room-goods-list {
    padding: 0 0.48rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .room-goods-item {
      width: calc(50vw - 0.63rem);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.48rem;
      overflow: hidden;
      padding: 0 0;
      margin-bottom: 0.3rem;
      position: relative;
      .pic-box {
        position: relative;
        img {
          width: 100%;
          height: calc(50vw - 0.63rem);
        }
        .tag {
          height: 0.28rem;
          line-height: 0.28rem;
          padding: 0 0.04rem;
          color: #fff;
          position: absolute;
          top: 0.16rem;
          left: 0.16rem;
          border-radius: 0.28rem;
          span {
            font-size: 12px;
            transform: scale(0.67);
            display: inline-block;
          }
        }
        .tag.show {
          background: linear-gradient(90deg, #00cdff 0%, #32f5db 49%, #63ff92 100%);
        }
        .tag.work {
          background: linear-gradient(
            90deg,
            #00cdff 0%,
            #2993ff 24%,
            #458fff 49%,
            #52d3ba 86%,
            #63ff92 100%
          );
        }
        .tag.done {
          background: #63ff92;
        }
      }
      .room-goods-info {
        padding: 0.12rem 0.12rem 0.3rem 0.16rem;
        .room-goods-name {
          height: 0.72rem;
          color: #333;
          font-size: 0.28rem;
          line-height: 0.36rem;
          margin-bottom: 0.08rem;
          text-align: left;
          
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow:hidden;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;

        }
        .room-data-line {
          display: flex;
          .data-item {
            margin-right: 0.2rem;
            height: 0.4rem;
            line-height: 0.4rem;
            img {
              width: 0.24rem;
              height: 0.24rem;
            }
            .num {
              color: #999999;
              font-size: 10px;
              transform: scale(0.83);
            }
          }
          .price {
            display: flex;
            border: 1px solid #00c9ab;
            height: 0.4rem;
            line-height: 0.4rem;
            border-radius: 0.08rem;
            img {
              width: 0.24rem;
              height: 0.24rem;
              margin-top: 0.07rem;
            }
            .num {
              font-size: 12px;
              transform: scale(0.83);
              color: #00c9ab;
            }
          }
        }
      }
      .sale-out {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        img {
          margin: 0 auto;
          margin-top: 0.5rem;
          width: 1.8rem;
          height: 1.8rem;
        }
        .word {
          color: #fff;
          font-size: 0.24rem;
          position: absolute;
          left: 48%;
          top: 1.28rem;
          transform: translateX(-50%) rotate(-17deg);
        }
      }
    }
  }
}
</style>
